<template>
    <section>
        <div v-for="(u,key) in usersGrupo" :key="key" class="row mx-0 mb-3" :class="{'bg-pendiente br-10':isEliminar(u.id_user)}">
            <div class="col-10 pl-2" @click="setResidente(u)">
                <div class="d-middle">
                    <div class="tipo-user wh-32px d-middle-center rounded-circle mr-2">
                        <i :class="`${isEliminar(u.id_user) ? 'icon-delete-outline rounded-circle bg-danger' : iconUser(u.tipo)} p-1`" />
                    </div>
                    <div class="tres-puntos">
                        <p class="f-15 f-500"> {{ u.nombre }}</p>
                        <p class="f-13 text-86 tres-puntos"> {{ u.vivienda }} </p>
                    </div>
                </div>
            </div>
            <div v-if="u.admin" class="col-2 text-right my-auto">
                <i class="icon-account-check-outline text-general f-22" />
            </div>
        </div>
    </section>
</template>
<script>
import {mapGetters } from 'vuex'
export default {
    props:{
        admin:{
            type:Boolean,
            default:false
        },
        eliminar:{
            type:Boolean,
            default:false
        }
    },
    data(){
        return{
            arrayEliminar:[]
        }
    },
    computed:{
        ...mapGetters({
            usersGrupo:'chatsGrupos/usersGrupo',
        })
    },
    methods:{
        async setResidente(user){
            if(this.admin){
                this.$emit('setAdmin',user)
            }
            if(this.eliminar){
                this.setEliminar(user)
            }
        },
        setEliminar(user){

            const findUser = this.arrayEliminar.findIndex(u => u === user.id_user)
            
            if(findUser > -1){
                this.arrayEliminar.splice(findUser,1)
            }else{
                this.arrayEliminar.push(user.id_user)
            }
            this.$emit('eliminar', this.arrayEliminar)
        },
        isEliminar(id){
            return _.includes(this.arrayEliminar, id);
        },
        clear(){
            this.arrayEliminar = []
        }
       
        
    }
}
</script>